import i18n from "i18next";
import memoize from "lodash/memoize";
import React, { memo } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import { config } from "../../Config";
import { getBounds } from "../map_util";
import { ReactLeafletGoogleLayer } from "../maps/GoogleLayer";
const { BaseLayer, Overlay } = LayersControl;

// the max zoom level should be the same for all layers, otherwise a
// layer will dissapear when zooming in past their own max. If a layer
// does not support that many zoomlevels set  maxNativeZoom to the max
// level the layer  supports.
const MAX_ZOOM = 21;

export const layers = memoize(() => ({
  // vertalingen pas na intialisatie actief, daarom in functie
  parcel: i18n.t("Fields"),
  soil: i18n.t("Soil type"),
  dem: i18n.t("Altitude"),
  gwp: i18n.t("Ground water protection"),
  cadastre: i18n.t("Cadastre"),
  nitrate: "Nitratbelastete Gebiete",
  n2k: "Natura 2000",
}));

const ref = memoize(() => ({
  tms: true,
  type: "data",
  maxNativeZoom: 18,
  maxZoom: MAX_ZOOM,
  bounds: getBounds(config.bounds),
  zIndex: 50,
}));
export const Layers = memo(({ layers, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png?country=DE_NW";
  const refp = ref();
  return (
    <LayersControl position="topright" autoZIndex={false}>
      <BaseLayer checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      <BaseLayer name={`${i18n.t("Orthophoto")} 2018`}>
        <WMSTileLayer
          url="https://www.wms.nrw.de/geobasis/wms_nw_dop"
          layers="nw_dop_rgb"
          attribution="Geobasis NRW"
          maxNativeZoom={19}
          maxZoom={MAX_ZOOM}
        />
      </BaseLayer>
      <BaseLayer name="Topographische Karte">
        <WMSTileLayer
          url="https://www.wms.nrw.de/geobasis/wms_nw_dtk10"
          layers="nw_dtk10_col"
          opacity={0.5}
          attribution="Geobasis NRW"
        />
      </BaseLayer>
      <BaseLayer name="Grundkarte">
        <WMSTileLayer
          url="https://www.wms.nrw.de/geobasis/wms_nw_dgk5"
          layers="nw_dgk5_grundriss"
          minZoom={16}
          attribution="Geobasis NRW"
        />
      </BaseLayer>
      <BaseLayer name="Amtliche Basiskarte">
        <WMSTileLayer
          url="https://www.wms.nrw.de/geobasis/wms_nw_abk"
          layers="nw_abk_col"
          minZoom={16}
          attribution="Geobasis NRW"
        />
      </BaseLayer>
      {/*<BaseLayer name="Ortho Vorläufig">*/}
      {/*  <WMSTileLayer*/}
      {/*    url="https://www.wms.nrw.de/geobasis/wms_nw_vdop"*/}
      {/*    layers="nw_vdop_rgb"*/}
      {/*    attribution="Geobasis NRW"*/}
      {/*  />*/}
      {/*</BaseLayer>*/}
      {/*TODO uitzoeken WMTS / TMS?*/}
      {/*<BaseLayer name="2019">*/}
      {/*  <TileLayer*/}
      {/*    url="https://www.wmts.nrw.de/geobasis/wmts_nw_dop/tiles/{layer}/EPSG_3857_15/{z}/{x}/{y}"*/}
      {/*    layer={"nw_dop"}*/}
      {/*  />*/}
      {/*</BaseLayer>*/}
      {/*<BaseLayer name="achtergrond">*/}
      {/*  <TileLayer*/}
      {/*    url="https://www.wmts.nrw.de/geobasis/wmts_nw_dtk/tiles/nw_dtk_col/EPSG_3857_16/{z}/{x}/{y}.png"*/}
      {/*  />*/}
      {/*</BaseLayer>*/}
      <BaseLayer name="Google maps">
        <ReactLeafletGoogleLayer type={"hybrid"} />
      </BaseLayer>
      <Overlay name={`${i18n.t("Fields")} ${config.crop_year}`} checked={layers.includes("parcel")}>
        <TileLayer url={histUrl} opacity={0.7} {...refp} />
      </Overlay>
      <Overlay name="Grundstucke O" checked={showPlotLayer}>
        <TileLayer url={histUrl + "&style=line"} opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Soil type")} checked={layers.includes("soil")}>
        <TileLayer url={layerUrl + "&mask=DELAND.05"} opacity={0.5} src="124" {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Altitude")} checked={layers.includes("dem")}>
        <WMSTileLayer
          url="https://www.wms.nrw.de/geobasis/wms_nw_hoehenschichten"
          layers="nw_hoehenschichten_rgb"
          opacity={0.5}
        />
      </Overlay>
      <Overlay name={i18n.t("Ground water protection area")} checked={layers.includes("gwp")}>
        <WMSTileLayer url="https://www.wms.nrw.de/umwelt/wsg" layers="4" opacity={0.5} />
      </Overlay>
      <Overlay name="Kadaster" checked={layers.includes("cadastre")}>
        {/*https://www.wms.nrw.de/geobasis/wms_nw_alkis?SERVICE=WMS&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=TRUE&STYLES=Farbe&VERSION=1.3.0&LAYERS=adv_alkis_flurstuecke&WIDTH=1379&HEIGHT=134&CRS=EPSG:25832&BBOX=295910.5529387399,5744231.8417756315,296734.0441326393,5744311.861949658*/}
        <WMSTileLayer
          url="https://www.wms.nrw.de/geobasis/wms_nw_alkis"
          opacity={1}
          minZoom={17}
          maxZoom={MAX_ZOOM}
          format="image/png"
          transparent={true}
          styles={"Grau"}
          layers="adv_alkis_flurstuecke"
        />
      </Overlay>
      <Overlay name="Nitratbelastete Gebiete" checked={layers.includes("nitrate")}>
        <TileLayer url={layerUrl} opacity={0.5} src="126" {...refp} />
      </Overlay>
      <Overlay name="Natura 2000" checked={layers.includes("n2k")}>
        <TileLayer url={layerUrl + "&mask=DELAND.05"} opacity={0.5} src="57" {...refp} />
        {/*http://www.wms.nrw.de/umwelt/wsg?LAYER=4&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&WIDTH=16&HEIGHT=16*/}
      </Overlay>
    </LayersControl>
  );
});
