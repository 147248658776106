import i18n from "i18next";
import memoize from "lodash/memoize";
import React, { memo } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import { config } from "../../Config";
import { ReactLeafletGoogleLayer } from "../maps/GoogleLayer";

const { BaseLayer, Overlay } = LayersControl;
export const layers = memoize(() => ({
  // vertalingen pas na intialisatie actief, daarom in functie
  parcel: i18n.t("Fields"),
  soil: i18n.t("Soil type"),
  gwp: i18n.t("Ground water protection"),
  cadastre: i18n.t("Cadastre"),
  organic: i18n.t("Organic"),
  permanent_grass: i18n.t("Permanent grassland"),
  dem: i18n.t("Altitude"),
  n2k: "Natura 2000",
}));
const token = config.countries.DK.token;
const kortforsyningenOpts = {
  attribution: 'Open data via <a href="https://services.kortforsyningen.dk">kortforsyningen</a>',
  token,
};

export const Layers = memo(({ layers, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png?country=DK";
  const refp = { zIndex: 50 };
  return (
    <LayersControl position="topright" autoZIndex={false}>
      <BaseLayer checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      <BaseLayer name={`${i18n.t("Orthophoto")} forår`}>
        <TileLayer
          url="https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar_webm/1.0.0/WMTS/orto_foraar_webm/default/DFD_GoogleMapsCompatible/{z}/{y}/{x}.jpg?username=BJSIGPGRVW&password=Panseryrtat*56klinge&token=ebd2ec7baaaa43e4d3f11ac8e8bd325c"
          // url="https://api.dataforsyningen.dk/topo_skaermkort_wmts_DAF?token={token}&layer={layer}&tilematrixset=DFD_GoogleMapsCompatible&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={y}&TileRow={x}"
          layer={`orto_foraar_webm`}
          {...kortforsyningenOpts}
        />
      </BaseLayer>
      {["2020", "2019", "2018", "2017", "2016", "2015"].map((year) => (
        <BaseLayer key={year} name={`${i18n.t("Orthophoto")} forår ${year}`}>
          <WMSTileLayer
            url="https://api.dataforsyningen.dk/orto_foraar_DAF"
            layers={`geodanmark_${year}_12_5cm`}
            {...kortforsyningenOpts}
          />
        </BaseLayer>
      ))}
      <BaseLayer name="Orto sommer 2008">
        <WMSTileLayer
          url="https://services.kortforsyningen.dk/service"
          servicename="orto_sommer_2008"
          layers="orto_sommer_2008"
          {...kortforsyningenOpts}
        />
      </BaseLayer>
      {/*<BaseLayer name="Top skærmkort">*/}
      {/*  <WMSTileLayer*/}
      {/*    url="https://services.kortforsyningen.dk/service"*/}
      {/*    servicename="topo_skaermkort"*/}
      {/*    layers="dtk_skaermkort"*/}
      {/*    {...kortforsyningenOpts}*/}
      {/*  />*/}
      {/*</BaseLayer>*/}
      <BaseLayer name="Google maps">
        <ReactLeafletGoogleLayer type={"hybrid"} />
      </BaseLayer>
      <Overlay name={`Percelen ${config.crop_year}`} checked={layers.includes("parcel")}>
        <TileLayer url={histUrl} tms={true} opacity={0.7} {...refp} />
      </Overlay>
      <Overlay name="Percelen O" checked={showPlotLayer}>
        <TileLayer url={histUrl + "&style=line"} tms={true} opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name="Jord" checked={layers.includes("soil")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="83" {...refp} />
      </Overlay>
      <Overlay name="Grundvandsbeskyttelse" checked={layers.includes("gwp")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="84" {...refp} />
      </Overlay>
      <Overlay name="Økologiske marker" checked={layers.includes("organic")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="91" {...refp} />
      </Overlay>
      <Overlay name="Natura 2000" checked={layers.includes("n2k")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="57" {...refp} />
      </Overlay>
      <Overlay name="Matrikel" checked={layers.includes("cadastre")}>
        <WMSTileLayer
          url="https://services.kortforsyningen.dk/service"
          opacity={1}
          format="image/png"
          transparent={true}
          servicename="cp_inspire"
          layers="CP.CadastralParcel"
          {...kortforsyningenOpts}
        />
      </Overlay>
      <Overlay name="Permanent græs" checked={layers.includes("permanent_grass")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="93" {...refp} />
      </Overlay>
      <Overlay name="Skyggekort" checked={layers.includes("dem")}>
        <WMSTileLayer
          url="https://services.kortforsyningen.dk/service"
          servicename="dhm"
          layers="dhm_terraen_skyggekort_overdrevet"
          {...kortforsyningenOpts}
        />
      </Overlay>
    </LayersControl>
  );
});
