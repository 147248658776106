import i18n from "i18next";
import memoize from "lodash/memoize";
import React, { memo } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import { config } from "../../Config";
import { ReactLeafletGoogleLayer } from "../maps/GoogleLayer";

const { BaseLayer, Overlay } = LayersControl;

const geopuntOpts = {
  attribution: 'Open data via <a href="https://geopunt.be">geopunt.be</a>',
};
export const layers = memoize(() => ({
  // vertalingen pas na intialisatie actief, daarom in functie
  parcel: i18n.t("Fields"),
  soil: i18n.t("Soil type"),
  gwp: i18n.t("Ground water protection"),
  dem: i18n.t("Altitude"),
  cadastre: i18n.t("Cadastre"),
  n2k: "Natura 2000",
  nitrate_area: i18n.t("Nitrate area") + " 2023",
  nitrate_area_2021: i18n.t("Nitrate area") + " 2021",
  vha: "VHA-" + i18n.t("waterways"),
  erosion: i18n.t("Erosion sensitivity"),
  property_i: i18n.t("Government property"),
}));

export const Layers = memo(({ layers, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png?country=BE";
  const refp = { zIndex: 50 };
  return (
    <LayersControl position="topright" autoZIndex={false}>
      <BaseLayer checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      {["2022", "2021", "2020", "2019", "2018", "2017", "2016", "2015"].map((year) => (
        <BaseLayer key={year} name={`${i18n.t("Orthophoto")} ${year}`}>
          <WMSTileLayer
            url="https://geo.api.vlaanderen.be/omw/wms"
            layers={`OMWRGB${year.substring(2)}VL`}
            {...geopuntOpts}
            minZoom={14}
          />
        </BaseLayer>
      ))}
      <BaseLayer name="Google maps">
        <ReactLeafletGoogleLayer type={"hybrid"} />
      </BaseLayer>
      <Overlay name={`${i18n.t("Fields")} ${config.crop_year}`} checked={layers.includes("parcel")}>
        <TileLayer url={histUrl} tms={true} opacity={0.7} {...refp} />
      </Overlay>
      <Overlay name="Percelen O" checked={showPlotLayer}>
        <TileLayer url={histUrl + "&style=line"} tms={true} opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name="Grondsoort" checked={layers.includes("soil")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="63" {...refp} />
      </Overlay>
      <Overlay name="Waterwin" checked={layers.includes("gwp")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="53" {...refp} />
      </Overlay>
      <Overlay name="Hoogte" checked={layers.includes("dem")}>
        <WMSTileLayer
          url="https://geo.api.vlaanderen.be/DHMV/wms"
          opacity={0.5}
          layers="DHMVII_DTM_1m"
          {...geopuntOpts}
        />
      </Overlay>
      <Overlay name="Natura 2000" checked={layers.includes("n2k")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="57" {...refp} />
      </Overlay>
      <Overlay name="Kadaster" checked={layers.includes("cadastre")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="cadastre" src="61" {...refp} />
      </Overlay>
      <Overlay name="VHA-waterlopen" checked={layers.includes("vha")}>
        <WMSTileLayer
          url="https://geo.api.vlaanderen.be/VMM/wms"
          opacity={0.8}
          layers="VHAWtlSegm"
          {...refp}
          attribution="&copy; <a href='https://vmm.be/'>www.vmm.be</a>"
        />
      </Overlay>
      <Overlay name="Nitraatgebiedstype 2023" checked={layers.includes("nitrate_area")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="175" {...refp} />
      </Overlay>
      <Overlay name="Nitraatgebiedstype 2021" checked={layers.includes("nitrate_area_2021")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="141" {...refp} />
      </Overlay>
      <Overlay name="Erosiegevoeligheid" checked={layers.includes("erosion")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="69" {...refp} />
      </Overlay>
      <Overlay name="Overheidseigendom" checked={layers.includes("property_i")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="95" {...refp} />
      </Overlay>

      {/*<Overlay name='Nitraat gevoelig/risico' checked={layers.includes('nitrate')}>*/}
      {/*<TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="68"/>*/}
      {/*</Overlay>*/}
    </LayersControl>
  );
});
