import i18n from "i18next";
import memoize from "lodash/memoize";
import React, { memo } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import { config } from "../../Config";
import { getBounds } from "../map_util";
import { ReactLeafletGoogleLayer } from "../maps/GoogleLayer";
const { BaseLayer, Overlay } = LayersControl;

export const layers = memoize(() => ({
  // vertalingen pas na intialisatie actief, daarom in functie
  parcel: i18n.t("Fields"),
  soil: i18n.t("Soil type"),
  gwp: i18n.t("Ground water protection"),
  n2k: "Natura 2000",
}));

// the max zoom level should be the same for all layers, otherwise a
// layer will dissapear when zooming in past their own max. If a layer
// does not support that many zoomlevels set  maxNativeZoom to the max
// level the layer  supports.
const MAX_ZOOM = 21;

const ref = memoize(() => ({
  tms: true,
  type: "data",
  maxNativeZoom: 18,
  maxZoom: MAX_ZOOM,
  bounds: getBounds(config.bounds),
  zIndex: 50,
}));
export const Layers = memo(({ layers, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png?country=DE_NI";
  const refp = ref();
  return (
    <LayersControl position="topright" autoZIndex={false}>
      <BaseLayer checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      <BaseLayer name="DOP">
        <WMSTileLayer
          url="https://www.geobasisdaten.niedersachsen.de/doorman/noauth/wms_ni_dop"
          layers="dop20"
          attribution="Auszug aus den Geodaten des <a href='https://www.lgln.de/'>Landesamtes für Geoinformation und Landesvermessung Niedersachsen</a>"
        />
      </BaseLayer>
      <BaseLayer name="Google maps">
        <ReactLeafletGoogleLayer type={"hybrid"} />
      </BaseLayer>
      <Overlay name={`${i18n.t("Fields")} ${config.crop_year}`} checked={layers.includes("parcel")}>
        <TileLayer url={histUrl} opacity={0.7} {...refp} />
      </Overlay>
      <Overlay name="Grundstucke O" checked={showPlotLayer}>
        <TileLayer url={histUrl + "&style=line"} opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Soil type")} checked={layers.includes("soil")}>
        <TileLayer url={layerUrl + "&mask=DELAND.03"} opacity={0.5} src="124" {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Ground water protection area")} checked={layers.includes("gwp")}>
        <TileLayer url={layerUrl + "&mask=DELAND.03"} opacity={0.5} src="125" {...refp} />
      </Overlay>
      <Overlay name="Natura 2000" checked={layers.includes("n2k")}>
        <TileLayer url={layerUrl + "&mask=DELAND.03"} opacity={0.5} src="57" {...refp} />
      </Overlay>
    </LayersControl>
  );
});
