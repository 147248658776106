import {
  createLayerComponent,
  LayerProps,
  LeafletContextInterface,
  updateGridLayer,
} from "@react-leaflet/core";
import * as L from "leaflet";
import "leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant";

// Lightly adapted from https://github.com/aviklai/react-leaflet-google-layer/blob/master/src/index.ts
interface IGoogleMapsAddLayer {
  name: "BicyclingLayer" | "TrafficLayer" | "TransitLayer";
  options?: any;
}

interface IProps {
  zIndex?: number;
  googleMapsAddLayers?: IGoogleMapsAddLayer[];
  apiKey?: string;
}

const createLeafletElement = (props: IProps, context: LeafletContextInterface) => {
  const {
    apiKey = "AIzaSyC6_fKnnuaiLyH6M0sjdP-AStLAMqwN73Y",
    googleMapsAddLayers,
    ...googleMutantProps
  } = props;

  const instance = (L as any).gridLayer.googleMutant(googleMutantProps);
  if (googleMapsAddLayers) {
    googleMapsAddLayers.forEach((layer) => {
      instance.addGoogleLayer(layer.name, layer.options);
    });
  }
  return { instance, context };
};

export const ReactLeafletGoogleLayer = createLayerComponent<L.GridLayer, LayerProps & IProps>(
  createLeafletElement,
  updateGridLayer
);
